<template>
  <b-card-code>
    <div class="d-flex justify-content-end">
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <!-- form -->
    <validation-observer ref="UserAddForm">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="addUser"
        autocomplete="off"
      >
        <!-- Email -->
        <b-form-group label="Email" label-for="email">
          <template #label>
            <span>Email</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="email|required"
          >
            <b-form-input
              id="email"
              v-model="email"
              :state="errors.length > 0 ? false : null"
              name="email"
              autocomplete="off"
              type="text"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Password -->
        <!-- <b-form-group label="Password" label-for="Password">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="password"
              v-model="password"
              @input="password_check"
              :type="passwordFieldType"
              name="password"
              placeholder="············"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <div class="row mt-1">
            <div class="col-4">
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': password.length > 7 }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="password.length ? 'fa-check' : 'fa-times'"
                />
                Longer than 7 characters
              </p>
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': has_uppercase }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="has_uppercase ? 'fa-check' : 'fa-times'"
                />
                Has a capital letter
              </p>
            </div>
            <div class="col-4">
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': has_lowercase }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="has_lowercase ? 'fa-check' : 'fa-times'"
                />
                Has a lowercase letter
              </p>
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': has_number }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="has_number ? 'fa-check' : 'fa-times'"
                />
                Has a number
              </p>
            </div>
            <div class="col-4">
              <p
                class="frmValidation"
                :class="{ 'frmValidation--passed': has_special }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="frmIcon"
                  :class="has_special ? 'fa-check' : 'fa-times'"
                />
                Has a special character
              </p>
            </div>
          </div>
        </b-form-group> -->

        <!-- First Name -->
        <b-form-group label="First Name" label-for="first Name">
          <template #label>
            <span>First Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="first_name"
            :rules="{
              required: true,
              regex: /^[a-zA-Z\s\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/,
            }"
          >
            <b-form-input
              id="first_name"
              v-model="first_name"
              :state="errors.length > 0 ? false : null"
              name="first_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Last Name -->
        <b-form-group label="Last Name" label-for="Last Name">
          <template #label>
            <span>Last Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="last_name"
            :rules="{
              required: true,
              regex: /^[a-zA-Z\s\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/,
            }"
          >
            <b-form-input
              id="last_name"
              v-model="last_name"
              :state="errors.length > 0 ? false : null"
              name="last_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group v-if="is_staff_login" label="" label-for="Is staff">
          <b-form-checkbox
            id="checkbox-1"
            v-model="is_staff"
            name="is_staff"
            value="true"
            unchecked-value="false"
          >
            Is Staff (System User)?
          </b-form-checkbox>
        </b-form-group>

        <!-- Organization-->
        <b-form-group
          label="Organization"
          label-for="Organization"
          v-if="(is_staff === false || is_staff === 'false') && is_staff_login"
        >
          <v-select
            v-model="organization"
            label="name"
            placeholder="--Select Organization--"
            :options="organizations"
            autocomplete
            :reduce="(organization) => organization.tenant_uid"
            disabled
          />

          <!-- <v-select
            v-model="organization"
            label="text"
            placeholder="--Select Organization--"
            :options="organizations"
            autocomplete
          /> -->
          <!-- disabled -->
          <!-- <v-select
            v-model="organization"
            label="text"
            placeholder="--Select Organization--"
            :options="organizations"
            @input="onOrgChange"
            autocomplete
            :reduce="(organization) => organization.value"
          />
        </b-form-group> -->
          <!-- <b-form-select v-model="organization">
            <option :value="org" v-for="(org, o) in organizations" :key="o">
              {{ org.text }}
            </option>
          </b-form-select> -->
        </b-form-group>

        <!-- Roles-->
        <b-form-group
          label="Role"
          label-for="Role"
          v-if="is_staff === false || is_staff === 'false'"
        >
          <template #label>
            <span>Role</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="role"
            :rules="{ required: true }"
          >
            <b-form-select v-model="role" :options="org_roles"></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Role" label-for="Role" v-else>
          <template #label>
            <span>Role</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="role"
            :rules="{ required: true }"
          >
            <b-form-select
              v-model="role"
              :options="system_roles"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="checkbox-10"
            name="checkbox-10"
            :checked="userInvitation"
            v-model="userInvitation"
          >
            User Invitation
          </b-form-checkbox>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="isBtnDisable || email === '' || first_name === '' || last_name === '' || role === ''"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Add</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BSpinner,
  BModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import { BTable } from 'bootstrap-vue'
// import { BPagination } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BSpinner,
    vSelect,
    BModal,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      is_staff_login: this.$store.state.app.user.is_staff,
      super_admin: this.$store.state.app.user.id == 1,
      email: "",
      password: "Test@123",
      first_name: "",
      last_name: "",
      is_staff: true,
      // organization: null,
      selectedOrganization: this.$store.state.app.org_id,
      organization: this.$store.state.app.org_id,
      // organizations: this.$store.state.app.organizations,
      // organization: this.$store.state.app.organizationId,
      // organization:!this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      role: "",
      // organizations: [{ value: null, text: "--Select Organization--" }],
      organizations: this.$store.state.app.workspaces,
      org_roles: [{ value: null, text: "--Select Role--" }],
      system_roles: [{ value: null, text: "--Select Role--" }],
      userInvitation: false,
      industry: null,
      country: null,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      message: "",

      isloading: false,
      isBtnDisable: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },

    // organization() {
    //   return this.organizations.find(
    //     (org) => org.value === this.$store.state.app.org_id
    //   );
    // },
    selectedOrganizationRefid() {
      if (!this.super_admin) {
        return this.$store.state.app.org_id;
      } else if (this.organization) {
        return this.organization.value;
      }
      return null;
    },
    selectedOrganizationId() {
      // this.$store.state.app.organizationId
      if (!this.super_admin) {
        return this.$store.state.app.organizationId;
      } else {
        return this.$store.state.app.organizationId;
      }
    },
  },
  created: function () {
    this.load();
  },
  methods: {
    // password_check: function () {
    //   this.has_number = /\d/.test(this.password);
    //   this.has_lowercase = /[a-z]/.test(this.password);
    //   this.has_uppercase = /[A-Z]/.test(this.password);
    //   this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password);
    // },
    load: function () {
      if (!this.is_staff_login) this.is_staff = false;
      var self = this;
      if (this.is_staff_login) {
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "organization/organization/get-all-org/",
        };
        this.$http(o_options).then((res) => {
          res.data.map(function (value, key) {
            let org = {
              value: res.data[key].org_id,
              text: res.data[key].org_name,
            };

            self.organizations.push(org);
          });
        });
      }
      // Load role dropdown
      const r_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "user/groups/",
      };
      this.$http(r_options).then((res) => {
        console.log(res.data);
        res.data.map(function (value, key) {
          let role = {
            value: res.data[key],
            text: res.data[key].name,
          };
          if (res.data[key].role_type == 0) self.system_roles.push(role);
          if (res.data[key].role_type == 1) self.org_roles.push(role);
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },

    addUser: function () {
      this.$refs.UserAddForm.validate().then((success) => {
        this.isBtnDisable = true;
        this.isloading = true;
        // if (success) {
        //call to axios
        let data = {
          username: this.email,
          email: this.email,
          password: this.password,
          is_active: true,
          first_name: this.first_name,
          last_name: this.last_name,
          is_staff: this.is_staff,
          is_invite: this.userInvitation,
        };
        // if (!this.is_staff_login) {
        //   this.organization = this.$store.state.app.organizationId;
        // }
        // && this.organization
        if (this.is_staff === false || this.is_staff === "false") {
          data["profile"] = {
            organization: this.selectedOrganizationId,
            is_organization_owner: false,
          };
        }
        // if((this.is_staff === false || this.is_staff ==='false') && this.role){
        if (this.role) {
          let groups_list = [];
          groups_list.push(this.role);
          data["groups"] = groups_list;
        }
        console.log(data);
        const options = {
          method: "POST",
          headers: { "content-type": "application/json" },
          data: data,
          url: process.env.VUE_APP_BASEURL + "user/users/",
        };
        var self = this;
        this.$http(options)
          .then((res) => {
            // console.log(res);
            if (res.data.id) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Added User Successfully",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.isloading = false;
              this.isBtnDisable = false;
              this.$router.push({ name: "Users" });
            }
            // else {
            //   debugger
            //   console.log(res, "else part is here dddd");
            //   self.$toast({
            //     component: ToastificationContent,
            //     props: {
            //       title: res,
            //       icon: "EditIcon",
            //       variant: "danger",
            //     },
            //   });
            // }
            // if (res.data.id) {
            //   this.$router.push({ name: "Users" });
            // }
          })
          .catch((err) => {
            this.isloading = false;
            this.isBtnDisable = false;
          });
        // }
      });
    },
  },
  mixins: [togglePasswordVisibility],
  // computed: {
  //   passwordToggleIcon() {
  //     return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
  //   },
  // },
};
</script>
<style lang="scss">
.frmField {
  background-color: white;
  color: #495057;
  line-height: 1.25;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  border: 0;
  padding: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  width: 90%;
}
.frmLabel {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.frmValidation {
  font-size: 13px;
}
.frmValidation--passed {
  color: #717b85;
}
.frmIcon {
  color: #eb0029;
}
.frmValidation--passed .frmIcon {
  color: #0fa140;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>