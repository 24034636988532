var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v("Back")])],1),_c('validation-observer',{ref:"UserAddForm"},[_c('b-form',{staticClass:"auth-login-form mt-2",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.addUser($event)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Email")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"email","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"email","autocomplete":"off","type":"text"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"First Name","label-for":"first Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("First Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"first_name","rules":{
            required: true,
            regex: /^[a-zA-Z\s\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false : null,"name":"first_name"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"Last Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Last Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"last_name","rules":{
            required: true,
            regex: /^[a-zA-Z\s\@\_\-\(\)\ [\]\ {\} \|\/ ]+$/,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false : null,"name":"last_name"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.is_staff_login)?_c('b-form-group',{attrs:{"label":"","label-for":"Is staff"}},[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"is_staff","value":"true","unchecked-value":"false"},model:{value:(_vm.is_staff),callback:function ($$v) {_vm.is_staff=$$v},expression:"is_staff"}},[_vm._v(" Is Staff (System User)? ")])],1):_vm._e(),((_vm.is_staff === false || _vm.is_staff === 'false') && _vm.is_staff_login)?_c('b-form-group',{attrs:{"label":"Organization","label-for":"Organization"}},[_c('v-select',{attrs:{"label":"name","placeholder":"--Select Organization--","options":_vm.organizations,"autocomplete":"","reduce":function (organization) { return organization.tenant_uid; },"disabled":""},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}})],1):_vm._e(),(_vm.is_staff === false || _vm.is_staff === 'false')?_c('b-form-group',{attrs:{"label":"Role","label-for":"Role"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Role")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,false,105094929)},[_c('validation-provider',{attrs:{"name":"role","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.org_roles},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1741534121)})],1):_c('b-form-group',{attrs:{"label":"Role","label-for":"Role"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Role")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"role","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.system_roles},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"checkbox-10","name":"checkbox-10","checked":_vm.userInvitation},model:{value:(_vm.userInvitation),callback:function ($$v) {_vm.userInvitation=$$v},expression:"userInvitation"}},[_vm._v(" User Invitation ")])],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":_vm.isBtnDisable || _vm.email === '' || _vm.first_name === '' || _vm.last_name === '' || _vm.role === '',"size":"sm"}},[(_vm.isloading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v("Add")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }